<template>
    <div class="inner_pages w-full">
        <div class="respective_content">
            <div class="fixed top-0 left-0 w-full bg-gray-5 flex items-center justify-between px-8 py-4.5 z-10">
                <router-link to="/contacts"><img src="@/assets/images/logo.png" class="w-8 h-auto mr-8" alt=""></router-link>
                <div class="max-w-1170 w-full mx-auto">
                    <router-link to="/contacts" class="flex items-center text-sm text-dark font-normal p-2.5"><i class="fas fa-chevron-left text-xs9 pr-2.5"></i>Home</router-link>
                </div>
            </div>
            <Form v-slot="{ errors }" @submit="handleSubmit" ref="onboarding-form" class="max-w-750 lg:max-w-1170 w-full mx-auto">
                <div class="flex flex-col lg:flex-row mb-3.5" v-if="!successCenter">
                    <div class="w-full rounded-t-lg lg:w-60 flex-shrink-0 flex flex-col bg-gray-9 lg:rounded-tr-none lg:rounded-l-lg pt-6 pb-3.5 px-8 xs:p-8">
                        <div class="relative max-w-400 w-full mx-auto lg:max-w-full">
                            <ul class="relative z-1 flex justify-between lg:block">
                                <li @click="step = 0" class="relative py-none px-1.5 lg:py-3.5 lg:px-none flex flex-col items-start lg:flex-row lg:items-center justify-start cursor-pointer">
                                    <span class="flex items-center justify-center w-4 h-4 bg-white border-2 border-gray-10 rounded-50pct text-xs font-medium text-dark-2 transition-all duration-300 ease-in-out" :class="{'border-4 border-primary' : step == 0 || step > 0, 'bg-primary' : step > 0}"><i class="fas fa-check text-white text-xs8" v-if="step > 0"></i></span>
                                    <h6 class="px-none py-1.5 lg:block lg:px-2.5 lg:py-none text-xs xs:text-sm font-normal text-dark text-center" :class="step == 0 ? 'block' : 'hidden'">Company Details</h6>
                                </li>
                                <li @click="unlockedStep >= 1 ? step = 1 : ''" class="relative py-none px-1.5 lg:py-3.5 lg:px-none flex flex-col items-center lg:flex-row lg:items-center justify-start cursor-pointer">
                                    <span class="flex items-center justify-center w-4 h-4 bg-white border-2 border-gray-10 rounded-50pct text-xs font-medium text-dark-2 transition-all duration-300 ease-in-out" :class="{'border-4 border-primary' : step == 1 || step > 1, 'bg-primary' : step > 1}"><i class="fas fa-check text-white text-xs8" v-if="step > 1"></i></span>
                                    <h6 class="px-none py-1.5 lg:block lg:px-2.5 lg:py-none text-xs xs:text-sm font-normal text-dark text-center" :class="step == 1 ? 'block' : 'hidden'">Message Settings</h6>
                                </li>
                                <li @click="unlockedStep >= 2 ? step = 2 : ''" class="relative py-none px-1.5 lg:py-3.5 lg:px-none flex flex-col items-center lg:flex-row lg:items-center justify-start cursor-pointer">
                                    <span class="flex items-center justify-center w-4 h-4 bg-white border-2 border-gray-10 rounded-50pct text-xs font-medium text-dark-2 transition-all duration-300 ease-in-out" :class="{'border-4 border-primary' : step == 2 || step > 2, 'bg-primary' : step > 2}"><i class="fas fa-check text-white text-xs8" v-if="step > 2"></i></span>
                                    <h6 class="px-none py-1.5 lg:block lg:px-2.5 lg:py-none text-xs xs:text-sm font-normal text-dark text-center" :class="step == 2 ? 'block' : 'hidden'">Brand Colors</h6>
                                </li>
                                <li @click="unlockedStep >= 3 ? step = 3 : ''" class="relative py-none px-1.5 lg:py-3.5 lg:px-none flex flex-col items-center lg:flex-row lg:items-center justify-start cursor-pointer">
                                    <span class="flex items-center justify-center w-4 h-4 bg-white border-2 border-gray-10 rounded-50pct text-xs font-medium text-dark-2 transition-all duration-300 ease-in-out" :class="{'border-4 border-primary' : step == 3 || step > 3, 'bg-primary' : step > 3}"><i class="fas fa-check text-white text-xs8" v-if="step > 3"></i></span>
                                    <h6 class="px-none py-1.5 lg:block lg:px-2.5 lg:py-none text-xs xs:text-sm font-normal text-dark text-center" :class="step == 3 ? 'block' : 'hidden'">Client Portal</h6>
                                </li>
                                <li @click="unlockedStep >= 4 ? step = 4 : ''" class="relative py-none px-1.5 lg:py-3.5 lg:px-none flex flex-col items-end lg:flex-row lg:items-center justify-start cursor-pointer">
                                    <span class="flex items-center justify-center w-4 h-4 bg-white border-2 border-gray-10 rounded-50pct text-xs font-medium text-dark-2 transition-all duration-300 ease-in-out" :class="{'border-4 border-primary' : step == 4 || step > 4, 'bg-primary' : step > 4}"><i class="fas fa-check text-white text-xs8" v-if="step > 4"></i></span>
                                    <h6 class="px-none py-1.5 lg:block lg:px-2.5 lg:py-none text-xs xs:text-sm font-normal text-dark text-center" :class="step == 4 ? 'block' : 'hidden'">Custom Login</h6>
                                </li>
                            </ul>
                            <div class="block lg:hidden absolute top-2 left-2 right-2 h-0.5 bg-gray-10 transform -translate-y-2/4">
                                <span class="absolute left-0 h-full bg-primary-light transition-all duration-300 ease-in-out" :style="`width:${(100 / 4) * step }%`"></span>
                            </div>
                            <div class="hidden lg:block absolute left-2 top-4 bottom-4 w-0.5 bg-gray-10 transform -translate-x-2/4">
                                <span class="absolute left-0 h-full bg-primary-light transition-all duration-300 ease-in-out" :style="`height:${(100 / 4) * step }%`"></span>
                            </div>
                        </div>
                        <div class="hidden bg-primary-light2 w-full p-3.5 lg:flex flex-col itemms-center text-sm leading-6 text-dark-gray rounded font-normal mt-auto text-center">
                            <img src="@/assets/images/bulb.svg" class="h-5 w-auto -mt-6 mb-2.5">
                            <template v-if="step == 0 || step == 1 || step == 2"> 
                                These settings can be adjusted anytime in your account admin settings.
                            </template>
                            <template v-if="step == 3 || step == 4">
                                Further customization options for your client portal are available in your client portal settings.
                            </template>
                        </div>
                    </div>
                    <div class="flex-auto min-h-700 flex flex-col bg-white rounded-b-lg lg:rounded-bl-none lg:rounded-r-lg px-6 py-8 xs:p-10 text-left">
                        <div class="max-w-800 mx-auto mb-6" v-if="step == 0">
                            <div class="text-xl lg:text-2xl font-medium mb-4.5 text-dark">Company Details</div>
                            <div class="setting_wpr mt-4">
                                <ul class="flex flex-wrap items-end -mx-1.5">
                                    <li class="w-full px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Company Name</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.businessname }">
                                                    <Field autocomplete="nofill" name="businessname" v-model="accountForm.businessname" type="text" rules="required" label="company name" placeholder="Your company name" />
                                                </div>
                                                <ErrorMessage name="businessname" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full xs:w-1/2 px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Owner</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.fname }">
                                                    <Field autocomplete="nofill" name="fname" v-model="accountForm.fname" rules="required" label="first name" type="text" placeholder="First Name" />
                                                </div>
                                                <ErrorMessage name="fname" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full xs:w-1/2 px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5"></div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.lname }">
                                                    <Field autocomplete="nofill" name="lname" v-model="accountForm.lname" rules="required" label="last name" type="text" placeholder="Last Name" />
                                                </div>
                                                <ErrorMessage name="lname" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full xs:w-1/2 px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Mobile Phone Number</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.contactno }">
                                                    <Field  name="contactno" autocomplete="nofill" type="text" v-model="accountForm.contactno" rules="required" label="mobile number" placeholder="+1 (814) 555 1212" />
                                                </div>
                                                <ErrorMessage name="contactno" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full xs:w-1/2 px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Website</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.website }">
                                                    <Field autocomplete="nofill" name="website" v-model="accountForm.website" type="text" placeholder="www.yoursite.com" />
                                                </div>
                                                <ErrorMessage name="website" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full px-1.5 flex flex-wrap justify-between items-center mt-7 mb-3.5">
                                        <div class="flex items-center py-1 mb-1.5">
                                            <label for="has_twilio_account" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="has_twilio_account" :true-value="1" :false-value="0" v-model="accountForm.has_twilio_account" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <h5 class="text-sm text-gray-11 font-normal text-left ml-3.5">Do you have a Twilio account?</h5>
                                        </div>
                                        <div class="flex items-start bg-gray-6 py-1 px-2.5 rounded text-xs font-normal text-gray-11 leading-4.5 text-left mb-1.5" v-if="accountForm.has_twilio_account">
                                            <img src="@/assets/images/bulb.svg" class="h-3.5 w-auto mr-2.5">You can always add this later in your admin settings.
                                        </div>
                                    </li>
                                    <template v-if="accountForm.has_twilio_account">
                                        <li class="w-full xs:w-1/2 md:w-1/3 px-1.5">
                                            <div class="form_grp py-2.5">
                                                <div class="group_item">
                                                    <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Account SID</div>
                                                    <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.twilio_sid }">
                                                        <Field autocomplete="off" type="text" name="twilio_sid" v-model="accountForm.twilio_sid" placeholder="Account SID" />
                                                    </div>
                                                    <ErrorMessage name="twilio_sid" class="text-danger" />
                                                </div>
                                            </div>
                                        </li>
                                        <li class="w-full xs:w-1/2 md:w-1/3 px-1.5">
                                            <div class="form_grp py-2.5">
                                                <div class="group_item">
                                                    <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Auth Token</div>
                                                    <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.twilio_token }">
                                                        <Field autocomplete="off" type="text" name="twilio_token" v-model="accountForm.twilio_token" placeholder="Auth Token" />
                                                    </div>
                                                    <ErrorMessage name="twilio_token" class="text-danger" />
                                                </div>
                                            </div>
                                        </li>
                                        <li class="w-full xs:w-1/2 md:w-1/3 px-1.5">
                                            <div class="form_grp py-2.5">
                                                <div class="group_item">
                                                    <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Twilio Phone Number</div>
                                                    <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.twilio_phone }">
                                                        <Field autocomplete="off" type="text" name="twilio_phone" v-model="accountForm.twilio_phone" placeholder="Twilio Number" />
                                                    </div>
                                                    <ErrorMessage name="twilio_phone" class="text-danger" />
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <div class="max-w-800 mx-auto mb-6" v-if="step == 1">
                            <div class="text-xl lg:text-2xl font-medium mb-4.5 text-dark">Message Settings</div>
                            <div class="setting_wpr mt-4">
                                <ul class="flex flex-wrap -mx-1.5">
                                    <li class="w-full xs:w-1/2 px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Where should we send SMS notifications?</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.sms_notification_to }">
                                                    <Field name="sms_notification_to" autocomplete="nofill" type="text" v-model="accountForm.sms_notification_to" rules="required" label="sms notification" placeholder="+1 (814) 555 1212" />
                                                </div>
                                                <ErrorMessage name="sms_notification_to" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full xs:w-1/2 px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Where should we send email notifications?</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.email_notification_to }">
                                                    <Field name="email_notification_to" autocomplete="nofill" type="text" v-model="accountForm.email_notification_to" rules="required" label="email notification" placeholder="email@emaildomain.com" />
                                                </div>
                                                <ErrorMessage name="email_notification_to" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full xs:w-1/2 px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">What FROM name would you like on outgoing emails?</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.email_from }">
                                                    <Field autocomplete="nofill" name="email_from" type="text" v-model="accountForm.email_from" rules="required" label="email from" placeholder="ex:XYZ Fitness" />
                                                </div>
                                                <ErrorMessage name="email_from" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full xs:w-1/2 px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Where should email replies be directed?</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.reply_to_email }">
                                                    <Field autocomplete="nofill" name="reply_to_email" type="email" v-model="accountForm.reply_to_email" rules="required" label="reply to email" placeholder="email@emaildomain.com" />
                                                </div>
                                                <ErrorMessage name="reply_to_email" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full xs:w-1/2 px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Where would you like to send test emails?</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.test_preview_email }">
                                                    <Field autocomplete="nofill" name="test_preview_email" type="email" v-model="accountForm.test_preview_email" rules="required" label="test email" placeholder="email@emaildomain.com" />
                                                </div>
                                                <ErrorMessage name="test_preview_email" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full xs:w-1/2 px-1.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">What country are you located in?</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.country }">
                                                    <Field autocomplete="off" name="country" v-model="accountForm.country" :class="{ 'has-error': errors.country }">
                                                        <multiselect
                                                            v-model="accountForm.country"
                                                            :options="countries"
                                                            value-prop="code"
                                                            label="country"
                                                            :searchable="true"
                                                            placeholder="Select country"
                                                            autocomplete="nofill"
                                                            ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="country" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full xs:w-1/2 px-1.5" v-if="accountForm.country">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">What is your current time zone?</div>
                                                <div class="field_wpr bg-gray-12" :class="{ 'has-error': errors.timezone }">
                                                    <Field autocomplete="off" name="timezone" v-model="accountForm.timezone" :class="{ 'has-error': errors.timezone }">
                                                        <multiselect
                                                            v-model="accountForm.timezone"
                                                            :options="timezones"
                                                            valueProp="code"
                                                            label="timezone"
                                                            :searchable="true"
                                                            autocomplete="nofill"
                                                            ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="timezone" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="max-w-800 mx-auto mb-6" v-if="step == 2">
                            <div class="text-xl lg:text-2xl font-medium mb-4.5 text-dark">Brand Colors</div>
                            <div class="setting_wpr mt-4">
                                <ul class="flex flex-wrap -mx-3.5">
                                    <li class="w-full sm2:w-1/2 py-2.5 px-3.5">
                                        <div class="flex flex-wrap items-center text-sm text-gray-11 font-normal text-left mb-2.5">
                                            Upload Your Logo
                                            <div class="pt-3.5 w-full flex flex-wrap items-center">
                                                <a href="https://www.canva.com/design/DAGOTFGfWT4/RD_xtxqp9aDtGBxwK27d4g/view?utm_content=DAGOTFGfWT4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="flex items-center justify-center py-1 px-2.5 mr-1.5 bg-white border-1 border-primary-light rounded-20 text-xxs leading-none text-primary font-medium text-center no-umderline">Rectangle Logo <img src="@/assets/images/canva.png" class="pl-1.5 h-2.5 w-auto"></a>
                                                <a href="https://www.canva.com/design/DAGOTc25tVw/v_9xNZxvzH40-dCqk2puZA/view?utm_content=DAGOTc25tVw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="flex items-center justify-center py-1 px-2.5 mr-1.5 bg-white border-1 border-primary-light rounded-20 text-xxs leading-none text-primary font-medium text-center no-umderline">Square Logo <img src="@/assets/images/canva.png" class="pl-1.5 h-2.5 w-auto"></a>
                                            </div>
                                        </div>
                                        <div class="upload_image">
                                            <image-upload v-model="accountForm.logo" image-type="public-logo" upload-text="Click to Upload" :is-avatar="true" />
                                        </div>
                                    </li>
                                    <li class="w-full sm2:w-1/2 py-2.5 px-3.5">
                                        <div class="flex flex-wrap items-center text-sm text-gray-11 font-normal text-left mb-2.5">
                                            Upload Your Headshot
                                            <div class="pt-3.5 w-full flex flex-wrap items-center">
                                                <a href="https://www.canva.com/design/DAGOTRVZQIM/8XqMSNXuRtTr3sG_hs6Kdw/view?utm_content=DAGOTRVZQIM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="flex items-center justify-center py-1 px-2.5 mr-1.5 bg-white border-1 border-primary-light rounded-20 text-xs text-primary font-medium text-center no-umderline"><img src="@/assets/images/canva.png" class="h-2.5 w-auto"></a>
                                            </div>
                                        </div>
                                        <div class="upload_image">
                                            <image-upload v-model="accountForm.profile_pic" image-type="coach-logo" upload-text="Click to Upload" :is-avatar="true" />
                                        </div>
                                    </li>
                                    <li class="w-full sm:w-1/2 px-3.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Primary Color</div>
                                                <Field autocomplete="off" name="primary_color" v-model="accountForm.primary_color" type="text" label="element color">
                                                    <color-picker v-model="accountForm.primary_color" :classes="{ 'has-error': errors.primary_color }" />
                                                </Field>
                                                <ErrorMessage name="primary_color" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="w-full sm:w-1/2 px-3.5">
                                        <div class="form_grp py-2.5">
                                            <div class="group_item">
                                                <div class="text-sm text-gray-11 font-normal text-left mb-2.5">Secondary Color</div>
                                                <Field autocomplete="off" name="secondary_color" v-model="accountForm.secondary_color" type="text" label="element color">
                                                    <color-picker v-model="accountForm.secondary_color" :classes="{ 'has-error': errors.secondary_color }" />
                                                </Field>
                                                <ErrorMessage name="secondary_color" class="text-danger" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="max-w-800 mx-auto mb-6" v-if="step == 3">
                            <div class="flex items-center justify-between text-xl lg:text-2xl font-medium mb-4.5 text-dark">
                                Client Portal
                                <div class="relative flex items-center cursor-pointer z-5" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                                    <label class="text-sm text-primary">Tutorial</label><img class="h-4.5 w-auto ml-1.5" src="@/assets/images/play.svg">
                                    <div class="dropdown_wpr left-auto right-0" :class="tutorialList ? 'active' : ''">
                                        <ul>
                                            <li class="text-xs font-normal text-dark-gray text-left whitespace-nowrap" @click="tutorial = true;">How to customize your client portal branding.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-gray-5 rounded pb-8 mt-4 overflow-hidden">
                                <div class="bg-gray-5 flex items-center justify-between p-2.5">
                                    <img v-if="portalForm.logo" class="h-3.5 w-auto" :src="portalForm.logo" alt="logo">
                                    <img v-else class="h-3.5 w-auto" :src="accountForm.logo ? accountForm.logo : require('@/assets/images/logo-alt.png')" alt="logo">
                                    <ul class="hidden md:flex items-center justify-center">
                                        <li class="flex items-center text-xxs leading-tight text-dark font-normal px-2.5 lg:px-3.5"><img class="h-2.5 w-auto mr-1.5" src="@/assets/images/home-solid.svg" alt="home">Home</li>
                                        <li class="flex items-center text-xxs leading-tight text-dark font-normal px-2.5 lg:px-3.5"><img class="h-2.5 w-auto mr-1.5" src="@/assets/images/calendar-solid.svg" alt="calendar">Calendar</li>
                                        <li class="flex items-center text-xxs leading-tight text-dark font-normal px-2.5 lg:px-3.5"><img class="h-2.5 w-auto mr-1.5" src="@/assets/images/classroom-solid.svg" alt="classroom">Classroom</li>
                                        <li class="flex items-center text-xxs leading-tight text-dark font-normal px-2.5 lg:px-3.5"><img class="h-2.5 w-auto mr-1.5" src="@/assets/images/dumbell.svg" alt="workouts">Workouts</li>
                                        <li class="flex items-center text-xxs leading-tight text-dark font-normal px-2.5 lg:px-3.5"><img class="h-2.5 w-auto mr-1.5" src="@/assets/images/charts-solid.svg" alt="charts">Tracking</li>
                                    </ul>
                                    <div class="flex items-center">
                                        <img class="h-5 w-auto rounded-50pct bg-gray-5 border-1 border-light-gray" :src="accountForm.profile_pic" alt="Coach">
                                        <span class="text-xxs leading-tight text-dark font-normal px-2.5">{{ portalForm.fname ? portalForm.fname : '' }}</span>
                                    </div>
                                </div>
                                <div class="relative flex flex-col flex-wrap items-center justify-center overflow-hidden">
                                    <img :src="portalForm.cover ? portalForm.cover : require('@/assets/images/thumb/default-cover-alt.svg')" alt="banner" class="hidden sm:block relative w-full h-auto z-1">
                                    <img :src="portalForm.small_cover ? portalForm.small_cover : require('@/assets/images/thumb/default-cover-3-2-alt.svg')" alt="banner" class="block sm:hidden relative w-full h-auto z-1">
                                    <span class="absolute left-0 top-0 w-full h-full z-2" :style="`background-color: ${portalForm.overlay_color}; opacity: ${(!portalForm.has_cover_overlay || portalForm.overlay_opacity == 0) ? '0': ((portalForm.overlay_opacity / 100 ))};`"></span>
                                    <span class="absolute top-3.5 right-3.5 w-5 h-5 text-xs9 text-gray-1 flex items-center justify-center rounded-50pct bg-white cursor-pointer z-4" @click="dashboardSetting = !dashboardSetting"><i class="fas fa-pen"></i></span>
                                    <div class="absolute left-0 right-0 top-0 bottom-0 px-8 py-6 flex flex-col items-center justify-center z-3">
                                        <div class="title relative text-center" v-if="portalForm.is_headline">
                                            <label class="text-lg sm:text-2xl font-medium text-white">
                                            {{ portalForm.headline ? portalForm.headline : 'Welcome To Your Client Portal'}}
                                            <span class="absolute top-1 -right-7 w-5 h-5 text-xs9 text-gray-1 flex items-center justify-center rounded-50pct bg-white cursor-pointer z-1" @click="handleInlineEdit($event, '.title')"><i class="fas fa-pen"></i></span>
                                            </label>
                                            <div class="rename_fld pr-12 relative w-80">
                                                <input type="text" v-model="portalForm.headline" class="w-full text-sm leading-5 font-medium text-dark p-2.5 bg-white rounded" placeholder="Welcome To Your Client Portal">
                                                <span class="absolute right-1 top-3 px-1.5 py-0.5 rounded-sm bg-primary text-xs9 leading-tight text-white uppercase cursor-pointer" @click="handleInlineEditSave($event, '.title', 'headline')">
                                                    <i class="fa fa-spin fa-spinner" v-if="inlineSaveLoader"></i>
                                                    {{ inlineSaveLoader ? 'Saving' : 'Save' }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="title relative mt-2.5 text-center" v-if="portalForm.is_subheadline">
                                            <label class="text-xs sm:text-sm font-medium text-white">
                                            {{ portalForm.description ? portalForm.description : 'This is where the magic happens!' }}
                                            <span class="absolute top-1 -right-7 w-5 h-5 text-xs9 text-gray-1 flex items-center justify-center rounded-50pct bg-white cursor-pointer z-1" @click="handleInlineEdit($event, '.title')"><i class="fas fa-pen"></i></span>
                                            </label>
                                            <div class="rename_fld pr-12 relative w-80">
                                                <input type="text" v-model="portalForm.description" class="w-full text-sm leading-5 font-medium text-dark p-2.5 bg-white rounded" placeholder="This is where the magic happens!">
                                                <span class="absolute right-1 top-3 px-1.5 py-0.5 rounded-sm bg-primary text-xs9 leading-tight text-white uppercase cursor-pointer" @click="handleInlineEditSave($event, '.title', 'description')">
                                                    <i class="fa fa-spin fa-spinner" v-if="inlineSaveLoader"></i>
                                                    {{ inlineSaveLoader ? 'Saving' : 'Save' }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-wrap p-2.5 mt-8 bg-white rounded max-w-xl mx-4.5 md:mx-auto">
                                    <h4 class="w-full px-3.5 text-sm font-normal text-dark mt-1.5 text-left">Welcome, {{ portalForm.fname ? portalForm.fname : '' }}</h4>
                                    <div class="w-full sm:w-1/2 px-2.5 py-3.5 sm:p-3.5">
                                        <img class="rounded-sm" src="@/assets/images/welcome.jpg" alt="Welcome Video">
                                    </div>
                                    <div class="w-full sm:w-1/2 px-2.5 py-1.5 sm:px-3.5 flex flex-col justify-between">
                                        <div class="flex justify-between items-center py-1.5">
                                            <div class="text-xs font-medium text-dark text-left">
                                                Meet your coach!
                                                <div class="block text-xs9 leading-3 mt-0.5 font-normal text-dark-gray">{{ portalForm.fname ? portalForm.fname : '' }} {{ portalForm.lname ? portalForm.lname : '' }} - MS, CPT, CSCS </div>
                                            </div>
                                            <ul class="flex flex-row-reverse ml-2.5">
                                                <li class="w-10 h-10 rounded-xl p-0.5 bg-white -mr-2 relative z-1">
                                                    <img v-if="portalForm.author" class="h-9 w-9 rounded-50pct bg-gray-3 border-1 border-light-gray" :src="portalForm.author" alt="Coach">
                                                    <img v-else class="h-9 w-9 rounded-50pct bg-gray-3 border-1 border-light-gray" :src="accountForm.profile_pic" alt="Coach">
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="text-xxs leading-4 font-normal text-dark-gray text-left py-1.5">I am so excited you're here. The next six weeks are going to be amazing. Click the Start Onboarding button below, complete your getting started steps, and get excited for our kickoff call!</div>
                                        <div class="text-xxs leading-4 font-normal text-dark text-left py-1.5">Start Onboarding <i class="fas fa-chevron-right text-xs9 text-dark pl-1.5"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-800 mx-auto mb-6" v-if="step == 4">
                            <div class="flex items-center justify-between text-xl lg:text-2xl font-medium mb-4.5 text-dark">
                                Custom Login
                                <div class="relative flex items-center cursor-pointer z-5" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                                    <label class="text-sm text-primary">Tutorials</label><img class="h-4.5 w-auto ml-1.5" src="@/assets/images/play.svg">
                                    <div class="dropdown_wpr left-auto right-0" :class="tutorialList ? 'active' : ''">
                                        <ul>
                                            <li class="text-xs font-normal text-dark-gray text-left whitespace-nowrap" @click="tutorial = true;">How to customize your login screen</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-gray-5 rounded pb-10 mt-4 overflow-hidden">
                                <div class="relative flex flex-col flex-wrap items-center justify-center overflow-hidden">
                                    <img :src="portalForm.login_setting.cover ? portalForm.login_setting.cover : require('@/assets/images/thumb/default-cover-alt.svg')" alt="banner" class="hidden sm:block relative w-full h-auto z-1">
                                    <img :src="portalForm.login_setting.small_cover ? portalForm.login_setting.small_cover : require('@/assets/images/thumb/default-cover-3-2-alt.svg')" alt="banner" class="block sm:hidden relative w-full h-auto z-1">
                                    <span class="absolute left-0 top-0 w-full h-full z-2" :style="`background-color: ${portalForm.login_setting.login_overlay_color}; opacity: ${(!portalForm.login_setting.has_login_cover_overlay || portalForm.login_setting.login_overlay_opacity == 0) ? '0': ((portalForm.login_setting.login_overlay_opacity / 100 ))};`"></span>
                                    <span class="absolute top-3.5 right-3.5 w-5 h-5 text-xs9 text-gray-1 flex items-center justify-center rounded-50pct bg-white cursor-pointer z-4" @click="loginSetting = !loginSetting; settingType = 'cover'"><i class="fas fa-pen"></i></span>
                                    <div class="absolute left-0 right-0 top-0 bottom-4 px-8 py-6 flex flex-col items-center justify-center z-3">
                                        <div class="title relative text-center" v-if="portalForm.login_setting.is_headline">
                                            <label class="text-lg sm:text-2xl font-medium text-white">
                                            {{ portalForm.login_setting.headline ? portalForm.login_setting.headline : 'Welcome To Your Client Portal'}}
                                            <span class="absolute top-1 -right-7 w-5 h-5 text-xs9 text-gray-1 flex items-center justify-center rounded-50pct bg-white cursor-pointer z-1" @click="handleInlineEdit($event, '.title')"><i class="fas fa-pen"></i></span>
                                            </label>
                                            <div class="rename_fld pr-12 relative w-80">
                                                <input type="text" v-model="portalForm.login_setting.headline" class="w-full text-sm leading-5 font-medium text-dark p-2.5 bg-white rounded" placeholder="Welcome To Your Client Portal">
                                                <span class="absolute right-1 top-3 px-1.5 py-0.5 rounded-sm bg-primary text-xs9 leading-tight text-white uppercase cursor-pointer" @click="handleInlineEdit($event, '.title')">Save</span>
                                            </div>
                                        </div>
                                        <div class="title relative mt-2.5 text-center" v-if="portalForm.login_setting.is_subheadline">
                                            <label class="text-xs sm:text-sm font-medium text-white">
                                            {{ portalForm.login_setting.subheadline ? portalForm.login_setting.subheadline : 'This is where the magic happens!' }}
                                            <span class="absolute top-1 -right-7 w-5 h-5 text-xs9 text-gray-1 flex items-center justify-center rounded-50pct bg-white cursor-pointer z-1" @click="handleInlineEdit($event, '.title')"><i class="fas fa-pen"></i></span>
                                            </label>
                                            <div class="rename_fld pr-12 relative w-80">
                                                <input type="text" v-model="portalForm.login_setting.subheadline" class="w-full text-sm leading-5 font-medium text-dark p-2.5 bg-white rounded" placeholder="This is where the magic happens!">
                                                <span class="absolute right-1 top-3 px-1.5 py-0.5 rounded-sm bg-primary text-xs9 leading-tight text-white uppercase cursor-pointer" @click="handleInlineEdit($event, '.title')">Save</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="relative z-4 flex flex-col p-6 -mt-6 bg-white rounded max-w-270 sm:max-w-300 mx-auto text-left">
                                    <h2 class="text-md font-medium text-dark mb-3.5">Secure Client Portal</h2>
                                    <div class="border-1 border-light-gray rounded-sm p-2.5 text-xs text-gray-1 mb-4.5">{{ user.email }}</div>
                                    <div class="border-1 border-light-gray rounded-sm p-2.5 text-xs text-gray-1 mb-4.5">************</div>
                                    <div class="flex items-center justify-between">
                                        <div class="flex items-center">
                                            <span class="w-2.5 h-2.5 border-1 border-gray-7 mr-1.5"></span>
                                            <div class="text-xxs leading-tight font-normal text-dark-gray">Remember me</div>
                                        </div>
                                        <div class="text-xxs leading-tight font-normal text-primary">Forgot Password?</div>
                                    </div>
                                    <div class="relative flex items-center justify-center rounded-sm p-2.5 text-sm font-normal text-center mt-4.5" :style="`background-color: ${portalForm.btn_color}; color: ${portalForm.btn_txtcolor};`">
                                        {{portalForm.btn_text}}
                                        <span class="absolute top-2.5 right-2 w-5 h-5 text-xs9 text-gray-1 flex items-center justify-center rounded-50pct bg-white cursor-pointer ml-2.5" @click="loginSetting = !loginSetting; settingType = 'button'"><i class="fas fa-pen"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-between max-w-800 w-full mx-auto mt-auto">
                            <div class="max-w-140 w-full flex items-center justify-center bg-white border-1 border-light-gray rounded py-1.5 px-4.5 text-sm leading-6 text-dark font-normal text-center mr-3.5 cursor-pointer" v-if="step > 0" @click="step--">
                                <img src="@/assets/images/arrow-left.svg" class="h-2.5 w-auto pr-2.5">Previous
                            </div>
                            <button :disabled="setupWizardLoader" class="max-w-140 w-full flex items-center justify-center bg-primary rounded py-1.5 px-4.5 text-sm leading-6 text-white font-normal text-center ml-auto cursor-pointer" v-if="step < 4">
                                <template v-if="setupWizardLoader">
                                    <i class="fa fa-spinner fa-spin"></i>&nbsp; Saving
                                </template>
                                <template v-else>
                                    Continue <img src="@/assets/images/arrow-left.svg" class="h-2.5 w-auto transform rotate-180 filter brightness-1000 pr-2.5">
                                </template>
                            </button>
                            <button :disabled="setupWizardLoader" class="max-w-140 w-full flex items-center justify-center bg-primary rounded py-1.5 px-4.5 text-sm leading-6 text-white font-normal text-center ml-auto cursor-pointer" v-if="step == 4">
                                <template v-if="setupWizardLoader">
                                    <i class="fa fa-spinner fa-spin"></i>&nbsp; Saving
                                </template>
                                <template v-else>
                                    Finish <img src="@/assets/images/arrow-left.svg" class="h-2.5 w-auto transform rotate-180 filter brightness-1000 pr-2.5">
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-lg px-6 py-8 xs:p-10 mb-3.5 text-center" v-if="successCenter">
                    <div class="max-w-900 w-full mx-auto sm:mt-4">
                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" class="success" :class="{'active' : successCenter}">
                            <path class="circle" d="M49.5 25.75C49.5 12.6332 38.8668 2 25.75 2C12.6332 2 2 12.6332 2 25.75C2 38.8668 12.6332 49.5 25.75 49.5C38.8668 49.5 49.5 38.8668 49.5 25.75Z" stroke="#00C200" stroke-width="4" stroke-linecap="round"/>
                            <path class="tick" d="M11.75 27.5L22.375 36.25L38.75 18" stroke="#00C200" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <div class="text-2xl sm:text-3xl font-normal sm:mb-4.5 text-dark">Awesome, You're all set up!</div>
                        <div class="flex flex-wrap py-4.5 sm:py-10">
                            <div class="w-full md:w-1/2 px-none py-4.5 xs:p-4.5">
                                <img class="rounded-sm" src="@/assets/images/academia.jpg" alt="Quick Start">
                            </div>
                            <div class="w-full md:w-1/2 px-none py-4.5 xs:p-4.5 flex flex-col justify-center text-left">
                                <div class="text-xl font-medium text-dark mb-0.5">Head to the</div>
                                <div class="text-3xl font-semibold mb-3.5 text-dark">Success Center!</div>
                                <div class="text-xl font-light text-dark">Complete Your Quick Start Onboarding</div>
                                <router-link :to="{ name: 'HomeIndex'}" class="max-w-200 w-full flex items-center justify-center bg-primary rounded py-2.5 px-4.5 text-base text-white font-normal text-center mt-10 cursor-pointer">Success Center<img src="@/assets/images/arrow-left.svg" class="h-2.5 w-auto transform rotate-180 filter brightness-1000 pr-2.5"></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </div>
    <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    <portal-dashboard-setting v-model="dashboardSetting" :form="portalForm"></portal-dashboard-setting>
    <portal-login-setting v-model="loginSetting" :setting-type="settingType" :form="portalForm"></portal-login-setting>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'

const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))
const PortalDashboardSetting = defineAsyncComponent(() => import('@/pages/account-setup/components/PortalDashboardSetting'))
const PortalLoginSetting = defineAsyncComponent(() => import('@/pages/account-setup/components/PortalLoginSetting'))


export default {
    name: 'Account Setup',

    data() {
        return {
            step: 0,
            dashboardSetting: false,
            loginSetting: false,
            settingType: 'cover',
            successCenter: false,
            tutorialList: false,
            tutorial: false,
            tutorialVideo: 'https://www.loom.com/embed/8c32baad69e64e5da5366f16618162d9?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
            accountForm: {
                id: 0,
                businessname: '',
                fname: '',
                lname: '',
                contactno: '',
                website: '',
                has_twilio_account: 0,
                twilio_sid: '',
                twilio_token: '',
                twilio_phone: '',
                sms_notification_to: '',
                email_notification_to: '',
                email_from: '',
                reply_to_email: '',
                test_preview_email: '',
                country: '',
                timezone: '',
                logo: '',
                profile_pic: '',
                primary_color: '#2f7eed',
                secondary_color: '#f2a31d',
            },
            portalForm: {
                id: 0,
                cover: '',
                small_cover: '',
                has_cover_overlay: '',
                overlay_opacity: '',
                headline: '',
                description: '',
                fname: '',
                lname: '',
                author: '',
                btn_text: 'Sign In',
                btn_color: '#612f5b',
                btn_txtcolor: '#FFFFFF',
                overlay_color: '#2F7EED',
                is_headline: 1,
                is_subheadline: 1,
                login_setting: {
                    cover: 'https://storage.googleapis.com/onboardme/uploads/media/undefined/2560x600/om-image-1720428288-4404259-33255.png',
                    headline: 'Welcome To Your Client Portal',
                    has_cover: 1,
                    cover_type: 2,
                    small_cover: 'https://storage.googleapis.com/onboardme/uploads/media/undefined/746X480/om-image-1720433536-939054-33255.png',
                    subheadline: 'This is where the magic happens!',
                    display_footer: 1,
                    overlay_opacity: '50',
                    has_cover_overlay: 1,
                    display_small_cover: 1,
                    headline_setting_shadow_x: 0,
                    headline_setting_shadow_y: 4,
                    headline_setting_font_size: 35,
                    headline_setting_font_color: '#2C3E50',
                    headline_setting_has_shadow: 0,
                    headline_setting_font_family: 'Inter',
                    headline_setting_font_weight: 500,
                    headline_setting_shadow_blur: 4,
                    subheadline_setting_shadow_x: 0,
                    subheadline_setting_shadow_y: 4,
                    headline_setting_shadow_color: '#2F7FED',
                    subheadline_setting_font_size: 30,
                    subheadline_setting_font_color: '#2C3E50',
                    subheadline_setting_has_shadow: 0,
                    subheadline_setting_font_family: 'Inter',
                    subheadline_setting_font_weight: 500,
                    subheadline_setting_shadow_blur: 4,
                    subheadline_setting_shadow_color: '#2F7FED',
                }
            },
            unlockedStep: 0,
            inlineSaveLoader: false,
        }
    },

    components: {
        Form,
        Field,
        ErrorMessage,
        ImageUpload,
        PortalDashboardSetting,
        PortalLoginSetting,
    },

    watch: {
        'accountForm.country' (country) {
            const vm = this;

            vm.getTimezonesByCountry({ country });
        },

        user: {
            handler (user) {
                const vm = this;

                vm.resetForm();

                if (user.country) {
                    vm.getTimezonesByCountry({ country: user.country });
                }
            },
            deep: true,
        },

        clientPortal: {
            handler () {
                const vm = this;

                vm.resetForm();
            },
            deep: true,
        },

        dashboardSetting (val) {
            const vm = this;

            if (!val) {
                vm.getClientPortal();
            }
        },

        loginSetting (val) {
            const vm = this;

            if (!val) {
                vm.getClientPortal();
            }
        }
    },

    computed: mapState({
        user: state => state.authModule.user,
        countries: state => state.commonModule.countries,
        timezones: state => state.commonModule.timezonesByCountry,
        clientPortal: state => state.clientPortalModule.clientPortal,
        clientPortalLoader: state => state.clientPortalModule.clientPortalLoader,
        setupWizardLoader: state => state.adminSettingModule.setupWizardLoader,
    }),

    mounted () {
        const vm = this;

        vm.refreshAuth();
        vm.getCountries();

        if (!vm.clientPortal.id || vm.lastRefreshedAt && moment().diff(vm.lastRefreshedAt, 'hours') > 1) {
            vm.getClientPortal();
        } else {
            vm.resetForm();
        }
    },

    methods: {
        ...mapActions({
            getCountries: 'commonModule/getCountries',
            getTimezonesByCountry: 'commonModule/getTimezonesByCountry',
            getClientPortal: 'clientPortalModule/getClientPortal',
            refreshAuth: 'authModule/refreshAuth',
            updateAccountSetting: 'adminSettingModule/updateAccountSetting',
            updatePortalSetting: 'adminSettingModule/updatePortalSetting',
        }),

        handleInlineEdit (ev, elm) {
            const el = ev.currentTarget.closest(elm);

            if (el.classList.contains('rename')) {
                el.classList.remove('rename');
            } else {
                el.classList.add('rename');
            }
        },

        handleInlineEditSave (ev, elm, field) {
            const vm = this;
            const el = ev.currentTarget.closest(elm);

            vm.inlineSaveLoader = true;

            vm.updatePortalSetting({ id: vm.portalForm.id, [field]: vm.portalForm[field] }).then((result) => {
                if (result) {
                    if (el.classList.contains('rename')) {
                        el.classList.remove('rename');
                    } else {
                        el.classList.add('rename');
                    }
                }

                vm.inlineSaveLoader = false;
            })
        },

        closeTutorialListDropdown () {
            const vm = this;

            vm.tutorialList = false;
        },

        resetForm () {
            const vm = this;

            vm.accountForm = {
                id: vm.user.id ? vm.user.id : 0,
                businessname: vm.user.businessname ? vm.user.businessname : '',
                fname: vm.user.fname ? vm.user.fname : '',
                lname: vm.user.lname ? vm.user.lname : '',
                contactno: vm.user.contactno ? vm.user.contactno : '',
                website: vm.website ? vm.website : '',
                has_twilio_account: vm.user.has_twilio_account ? vm.user.has_twilio_account : 0,
                twilio_sid: vm.user.twilio_sid ? vm.user.twilio_sid : '',
                twilio_token: vm.user.twilio_token ? vm.user.twilio_token : '',
                twilio_phone: vm.user.twilio_phone ? vm.user.twilio_phone : '',
                sms_notification_to: vm.user.sms_notification_to ? vm.user.sms_notification_to : vm.user.contactno,
                email_notification_to: vm.user.email_notification_to ? vm.user.email_notification_to : vm.user.email,
                email_from: vm.user.email_from ? vm.user.email_from : vm.user.full_name,
                reply_to_email: vm.user.reply_to_email ? vm.user.reply_to_email : vm.user.email,
                test_preview_email: vm.user.test_preview_email ? vm.user.test_preview_email : vm.user.email,
                country: vm.user.country ? vm.user.country : '',
                timezone: vm.user.timezone ? vm.user.timezone : '',
                logo: vm.user.logo ? vm.user.logo : '',
                profile_pic: vm.user.profile_pic ? vm.user.profile_pic : '',
                primary_color: vm.user.primary_color ? vm.user.primary_color : '#2f7eed',
                secondary_color: vm.user.secondary_color ? vm.user.secondary_color : '#f2a31d',
            };

            vm.portalForm = {
                id: vm.clientPortal.id ? vm.clientPortal.id : 0,
                cover: vm.clientPortal.cover ? vm.clientPortal.cover : '',
                small_cover: vm.clientPortal.small_cover ? vm.clientPortal.small_cover : '',
                has_cover_overlay: vm.clientPortal.has_cover_overlay ? vm.clientPortal.has_cover_overlay : 0,
                overlay_opacity: vm.clientPortal.overlay_opacity ? vm.clientPortal.overlay_opacity : 50,
                headline: vm.clientPortal.headline ? vm.clientPortal.headline : '',
                description: vm.clientPortal.description ? vm.clientPortal.description : '',
                fname: vm.user.fname ? vm.user.fname : '',
                lname: vm.user.lname ? vm.user.lname : '',
                author: vm.clientPortal.author ? vm.clientPortal.author : '',
                btn_text: vm.clientPortal.btn_text ? vm.clientPortal.btn_text : '',
                btn_color: vm.clientPortal.btn_color ? vm.clientPortal.btn_color : '#2F7EED',
                btn_txtcolor: vm.clientPortal.btn_txtcolor ? vm.clientPortal.btn_txtcolor : '#FFFFFF',
                is_headline: vm.clientPortal.is_headline ? vm.clientPortal.is_headline : 0,
                is_subheadline: vm.clientPortal.is_subheadline ? vm.clientPortal.is_subheadline : 0,
                overlay_color: vm.clientPortal.overlay_color ? vm.clientPortal.overlay_color : '#2F7EED',
                login_setting: vm.clientPortal.login_setting ? JSON.parse(vm.clientPortal.login_setting) : {},
            };
        },

        handleSubmit (form, { setFieldError }) {
            const vm                = this;
            const params            = JSON.parse(JSON.stringify(vm.accountForm));
            params.step             = vm.step;
            params.setFieldError    = setFieldError;

            vm.updateAccountSetting(params).then((result) => {
                if (result) {
                    if (vm.step == 4) {
                        confetti({
                            particleCount: 500,
                            spread: 1000,
                            origin: { y: 0.5 },
                        });

                        vm.successCenter = true;
                    } else {
                        if (!vm.accountForm.sms_notification_to) {
                            vm.accountForm.sms_notification_to = result.contactno;
                        }

                        vm.step++;
                        vm.unlockedStep++;
                    }
                }
            });
        },
    }
}
</script>

<style scoped>
.dropdown_wpr:before {
    display: none;
}

:deep(.upload_image .tab_content),
.modal :deep(.tab_content) {
    height: auto;
}

:deep(.upload_image .upload_wpr .upload_area) {
    background: #f9f9f9;
}

.title .rename_fld,
.title.rename label {
    display: none;
}

.title.rename .rename_fld {
    display: flex;
}

:deep(.button-uploader) {
    position: absolute;
    left: 25px;
    top: 15px;
    background: #fff;
    z-index: 5;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}

:deep(.button-uploader .upload_btn) {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #fff;
    z-index: 3;
}

:deep(.upload_btn .select_img img) {
    max-height: 15px;
}

.success {
    margin: 0 auto 30px auto;
}

.success .circle {
    stroke-dasharray: 160;
    stroke-dashoffset: 160;
}

.success.active .circle {
    animation: circle 1s ease-in-out;
    animation-fill-mode: forwards;
}

.success .tick {
    stroke-dasharray: 40;
    stroke-dashoffset: 40;
}

.success.active .tick {
    animation: tick .3s ease-out;
    animation-fill-mode: forwards;
    animation-delay: .4s;
}

@keyframes circle {
    from {
        stroke-dashoffset: 160;
    }

    to {
        stroke-dashoffset: 320;
    }
}

@keyframes tick {
    from {
        stroke-dashoffset: 40;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@media(max-width: 599px) {
    .text_content {
        margin: 20px 0;
    }
}
</style>