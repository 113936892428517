<template>
    <div class="preview_area show_sidebar">
        <div class="global_setting">
            <ul class="tabs_btn">
                <li :class="tab === 'market' ? 'active' : ''" @click="tab = 'market'">Marketing</li>
                <li :class="tab === 'coach' ? 'active' : ''" @click="tab = 'coach'">Coaching</li>
                <li :class="tab === 'admin' ? 'active' : ''" @click="tab = 'admin'">Admin</li>
                <li :class="tab === 'help' ? 'active' : ''" @click="tab = 'help'">Help</li>
                <li :class="tab === 'apps' ? 'active' : ''" @click="tab = 'apps'">Apps </li>
                <li :class="tab === 'importer' ? 'active' : ''" @click="tab = 'importer'">Importer</li>
            </ul>
            <div class="tabs_content">
                <div class="content_area">
                    <div class="section_content w-100" v-if="tab === 'market'">
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Broadcasts" img="broadcasts" link="/pipelines" />
                                </li>
                                <li>
                                    <card title="Sequence" img="sequences" link="/pipelines" />
                                </li>
                                <li>
                                    <card title="Pipelines" img="pipelines" link="/pipelines" />
                                </li>
                                <li>
                                    <card title="Journeys" img="journeys" link="/pipelines" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'coach'">
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Client Portal" img="client-portal" link="/client-portal" />
                                </li>
                                <li>
                                    <card title="Playbooks" img="playbooks" link="/tags" />
                                </li>
                                <li>
                                    <card title="Pages" img="pages" link="/groups" />
                                </li>
                                <li>
                                    <card title="Forms" img="forms" link="/groups" />
                                </li>
                                <li>
                                    <card title="Habits" img="habits" link="/groups" />
                                </li>
                                <li>
                                    <card title="Progress" img="progress" link="/groups" />
                                </li>
                                <li>
                                    <card title="Workouts" img="forms2" :btn-trigger="openOmSchedulingModal"/>
                                </li>
                                <li>
                                    <card title="Tasks" img="tasks" link="/tasks" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'admin'">
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Messaging" img="message" link="/groups" />
                                </li>
                                <li>
                                    <card title="Settings" img="Admin-Settings" link="/groups" />
                                </li>
                                <li>
                                    <card title="Groups" img="groups" link="/groups" />
                                </li>
                                <li>
                                    <card title="Tags" img="tags" link="/tags" />
                                </li>
                                <li>
                                    <card title="Files" img="files" link="/files" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'help'">
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Setup Guide" img="forms2" link="https://pages.onboardme.io" />
                                </li>
                                <li>
                                    <card title="Thrive Assessment" img="forms" link="https://app.omcheckout.com/login" />
                                </li>
                                <li>
                                    <card title="Thrive Call" img="sorry" />
                                </li>
                                <li>
                                    <card title="Thrive Academy" img="om-scheduling" :btn-trigger="openOmSchedulingModal"/>
                                </li>
                                <li>
                                    <card title="Help Center" img="Admin-Settings" />
                                </li>
                                <li>
                                    <card title="Thrive Marketplace" img="offers" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'apps'">
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Thrive Checkout" img="om-checkout" :btn-trigger="openOmSchedulingModal" isUpcoming/>
                                </li>
                                <li>
                                    <card title="Thrive Scheduling" img="om-scheduling" :btn-trigger="openOmSchedulingModal" isUpcoming/>
                                </li>
                                <li>
                                    <card title="Thrive Funnels" content-type="fit-funnel" img="fit-funnels" link="https://pages.onboardme.io" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'importer'">
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Content Importer" img="forms2" isOption :btn-trigger="openContentImportWizard" :option-trigger="openOptinModal"/>
                                </li>
                                <li>
                                    <card title="Content Club" content-type="content-club" img="content-club" :btn-trigger="openContentClub"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="app_card">
            <div class="modal secondary" v-if="omSchedulingModal" :class="{ active : omSchedulingModal }" @click.self="omSchedulingModal = false">
                <div class="modal_container">
                    <img src="@/assets/images/alert.svg" alt="" class="alert_img">
                    <h2>Coming in 2023 !</h2>
                    <button type="button" class="ok_btn" @click="omSchedulingModal = false">Okay</button>
                </div>
            </div>
            <content-club v-model="contentClubModal" />
            <content-import-history v-model="contentImportHistory" />
            <content-import-wizard v-model="contentImportWizard" :toggle-history="openOptinModal" />
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'

    const Card = defineAsyncComponent(() => import('@/pages/apps/components/Card'))
    const ContentClub = defineAsyncComponent(() => import('@/pages/apps/components/ContentClub'))
    const ContentImportHistory = defineAsyncComponent(() => import('@/pages/apps/components/ContentImportHistory'))
    const ContentImportWizard = defineAsyncComponent(() => import('@/pages/apps/components/ContentImportWizard'))

    export default {
        name: 'Apps Index',

        data () {
            return {
                tab: 'market',
                omSchedulingModal: false,
                contentClubModal: false,
                contentImportWizard: false,
                contentImportHistory: false,
            };
        },

        components: {
            Card,
            ContentClub,
            ContentImportHistory,
            ContentImportWizard,
        },

        methods: {
            openOmSchedulingModal () {
                const vm = this;

                vm.omSchedulingModal = true;
            },

            openOptinModal (type) {
                const vm = this;

                vm[type] = true;
            },

            openContentImportWizard () {
                const vm = this;

                vm.contentImportWizard = true;
            },

            openContentClub(){
                const vm = this;

                vm.contentClubModal = true;
            }
        },
    }
</script>
